<template>
  <component
    :is="elementType"
    :localizedData="localizedData"
    name="publications-slug"
    :class="$style.root"
    :style="{
      '--deg': deg + 'deg',
      '--origin': origin,
      '--width--percentage': coverImageWidth,
      '--aspect-ratio': aspectRatio
    }"
    :data-idle="idle ? '' : null"
    data-modal-link
  >
    <BaseStrapiImageComponent
      v-if="localizedData?.cover_image?.data?.attributes"
      :image="localizedData?.cover_image?.data?.attributes"
      :class="$style.image"
      :data-thickness="localizedData?.thickness || 'default'"
    />

    <div
      :class="$style.shadow"
      v-for="i in steps"
      :style="{
        '--i': i
      }"
    />
  </component>
</template>

<script setup>
import { sample, random, range, inRange } from 'lodash-es'
import i18nLinkWithFallBack from '~/components/i18n/link-with-fallback.vue'
const nuxtApp = useNuxtApp()

// 1
const props = defineProps({
  data: Object,
  elementType: {
    type: [String, Object, Function],
    default: i18nLinkWithFallBack
  }
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.data))
})

// 2
const setRandomDeg = () => {
  const negative = sample([true, false])
  return random(2, 6) * (negative ? -1 : 1)
}
const deg = ref(setRandomDeg())

const setRampleOrigins = () => {
  const origins = [
    'bottom right',
    'bottom left',
  ]
  return sample(origins)
}
const origin = ref(setRampleOrigins())

const aspectRatio = computed(() => {
  if (props?.ratio) {
    return localizedData.value?.cover_image?.data?.attributes?.ratio
  } else {
    return localizedData.value?.cover_image?.data?.attributes?.width / localizedData.value?.cover_image?.data?.attributes?.height || 1
  }
})

const steps = computed(() => {
  switch(localizedData.value?.thickness) {
    case 'thin':
      return 4
    case 'thick':
      return 6
    default:
      return 8
  }
})

const idle = computed(() => nuxtApp.$getRouteBaseName() === 'publications-slug')

// 3
const coverImageWidth = computed(() =>{
  const min = 110 // min
  const max = 220 // max
  const increment = 10
  const average = max - min / 2

  let width = null
  if (localizedData.value?.cover_image?.data?.attributes?.width_in_mm < min) {
    width = min
  } else if (localizedData.value?.cover_image?.data?.attributes?.width_in_mm >= min && localizedData.value?.cover_image?.data?.attributes?.width_in_mm <= max) {
    width = localizedData.value?.cover_image?.data?.attributes?.width_in_mm
  } else if (localizedData.value?.cover_image?.data?.attributes?.width_in_mm > min && localizedData.value?.cover_image?.data?.attributes?.width_in_mm > max) {
    width = max
  } else {
    width = average
  }

  const ranges = range(min, max, increment)?.map((step, index) => {
    const belowAverage = step < average
    const diff = Math.abs(average - step) * .075
    return {
      range: [step, step + increment],
      diff: (belowAverage ? (diff * -1) : diff) / 100
    }
  })

  const diff = ranges?.find((range) => {
    return inRange(width, ...range?.range)
  })?.diff

  return 1 + diff
})
</script>

<style module lang=scss>
.root {
  composes: reset-link from global;

  display: block;

  aspect-ratio: var(--aspect-ratio);
  position: relative;

  transition: transform 0.2s ease-in-out;
  transform-origin: var(--origin);

  width: calc(var(--width--percentage) * var(--width--publication-thumbnail));
  max-width: 100%;
}

.root[data-idle] {
  pointer-events: none;
}

.root:focus,
.root:global(.router-link-exact-active),
.root:hover {
  transform: rotate(var(--deg, 0deg));
  outline: none !important;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--color--white);
}

.image:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.01);
}

.shadow {
  --offset: -1.35px;
  --offset-top: -1.15px;
  position: absolute;
  top: calc(var(--offset-top) * var(--i));
  right: calc(var(--offset) * var(--i));
  height: 100%;
  width: 100%;
  z-index: calc(-1  * var(--i));
  background-color: #ebebeb;
  border-right: .5px solid #cfcfcf;

  @media (max-width: $breakpoint-s) {
    --offset: -.8px;
    --offset-top: -.65px;
  }
}
</style>
